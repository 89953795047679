'use client'

import dynamic from 'next/dynamic'
import React, {PropsWithChildren} from 'react'

const Boot = dynamic<PropsWithChildren>(
  async () => {
    // @ts-ignore code detection of dynamic typescript files may not work for some IDE
    const SSREmptyPageBoot = await import('~@app/boot/SSREmptyPageBoot')
    return SSREmptyPageBoot
  },
  {ssr: false},
)

const EmptyPageBoot = ({children, ...props}) => {
  return <Boot {...props}>{children}</Boot>
}

export default EmptyPageBoot