import React, {useState} from "react";
import EmptyPageBoot from "~@app/boot/EmptyPageBoot"
import AuthRedirectWrapper from "~@app/component/auth/AuthRedirectWrapper"
import useAuth from "~@app/component/auth/UseAuth"
import NotFoundPage from "~@app/component/page/NotFoundPage"
import FamilyService from "~@app/module/core/service/FamilyService"
import {useAsyncEffect} from "~@core/hook/ReactHooks"
import StorageUtil from "~@core/util/StorageUtil"
import UrlUtil from "~@core/util/UrlUtil"

function HomeDispatcher() {
  const [notFound, setNotFound] = useState(false)

  const {me} = useAuth();

  useAsyncEffect(async () => {
    const lastFamily = StorageUtil.get('lastFamilyCode');
    if (lastFamily) {
      window.location.href = UrlUtil.toUrlString(lastFamily);
    } else if (me?.familyId) {
      const [family] = await FamilyService.get(me.familyId);
      if (family.code) {
        window.location.href = UrlUtil.toUrlString(family.code);
      } else {
        setNotFound(true)
      }
    }
  }, []);


  return <div>
    {notFound && <NotFoundPage/>}
  </div>
}

export default function Index() {
  return (
    <EmptyPageBoot>
      <AuthRedirectWrapper mustAuthenticated>
        <HomeDispatcher/>
      </AuthRedirectWrapper>
    </EmptyPageBoot>
  )
}