import _ from 'lodash';
import Http from '~@core/util/Http';
import StorageUtil from '~@core/util/StorageUtil';

export default class BaseService {
  protected http: Http;

  constructor() {
    this.http = new Http();
    this.init();
  }

  init() {
    // this.http.addHeaders({
    //   Authorization: this.getAccessToken,
    // });
  }

  getAccessToken() {
    const ret = _.get(StorageUtil.getObject('auth'), 'accessToken')
    if (!ret) {
      return '';
    }
    return `Bearer ${ret}`;
  }
}
