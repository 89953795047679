import User from "~@app/module/core/model/User"
import CRUDService from "~@core/service/CRUDService";
import API from "~@app/const/API";
import Family from "~@app/module/core/model/Family";
import {SuccessResponse} from "~@core/type/ApiType";
import FamilyMember from "~@app/module/core/model/FamilyMember";
import {AnyObject} from "~@core/type/Common"

class FamilyService extends CRUDService<Family> {

  async addChildren(data): Promise<SuccessResponse<FamilyMember>> {
    const ret = await this.http.callPost(`${this.commandPath}/add-children`, {...data});
    return ret as SuccessResponse<FamilyMember>;
  }

  async addSpouse(data): Promise<SuccessResponse<FamilyMember>> {
    const ret = await this.http.callPost(`${this.commandPath}/add-spouse`, {...data});
    return ret as SuccessResponse<FamilyMember>;
  }

  async addRoot(data): Promise<SuccessResponse<FamilyMember>> {
    const ret = await this.http.callPost(`${this.commandPath}/add-root`, {...data});
    return ret as SuccessResponse<FamilyMember>;
  }

  async addUser(id: string, data) {
    const ret = await this.http.callPost(`${this.commandPath}/add-user`, {familyId: id, ...data});
    return ret as SuccessResponse<User>;
  }

  async getByCode(code: string, payload?: AnyObject) {
    const ret = await this.http.callGet(`${this.commandPath}/find-by`, {
      ...payload,
      filters: [['code', '=', code]]
    });
    return ret as SuccessResponse<Family>;
  }
}

export default new FamilyService(`${API.BASE.API_COMMAND_PATH}family`, `${API.BASE.API_QUERY_PATH}family`);